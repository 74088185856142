<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container>
      <productLinks
        :add-enabled="enableAdd"
        :title="this.$t('titleProductLinks')"
      />
    </v-container>
  </div>
</template>

<script>
  export default {
    Name: 'Links',
    components: {
      productLinks: () => import('@/views/components/products/ProductLinks'),
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
    },
    data: function () {
      return {
        appTitle: this.$t('bcLinks'),
        enableAdd: true,
        breadcrumbs: [
          {
            text: this.$t('bcHome'),
            disabled: false,
            href: '/dashboard',
          },
          {
            text: this.$t('bcSettings'),
            disabled: true,
            href: '/settings',
          },
          {
            text: this.$t('bcLinks'),
            disabled: true,
            href: '/settings/listings',
          },
        ],
      }
    },
  }
</script>
